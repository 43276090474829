import React from "react"
import { Location } from "@reach/router"

const Topbar = () => (
  <Location>
    {({ location }) =>
      location.pathname === "/" ? (
        <div
          style={{
            borderTop: "10px solid var(--main-accent-color)",
          }}
        />
      ) : (
        <div
          style={{
            borderTop: "10px solid var(--black)",
          }}
        />
      )
    }
  </Location>
)

export default Topbar
