import React from "react"
import styled from "styled-components"

const FooterSection = styled.footer`
  padding: 2rem 0;
  text-align: center;
  a:hover {
    border-bottom: 1px solid var(--main-accent-color);
  }
`

const Footer = () => (
  <FooterSection>
    &copy; {new Date().getFullYear()}&nbsp;-&nbsp;
    <a href="https://brunobrito.pt" target="_blank" rel="noopener noreferrer">
      Bruno Brito
    </a>
  </FooterSection>
)

export default Footer
